import { Icon, Paragraph, Popover } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { useTaxesAndFeesMessage } from './getTaxesAndFees';
import { usePriceQuoteUnit } from './usePriceQuoteUnit';
import { Pricing } from '@AuroraTypes';
import { BasketSummaryDetail } from '@Components/Checkout/Basket/BasketSummaryDetail/BasketSummaryDetail';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

export type TotalPricePopoverProps = {
  pricing: Pick<Pricing, 'displayedPricingItems' | 'totalPaidAtHotel'>;
  localCurrency: string | undefined;
  mayHaveFees: boolean | undefined;
};

export const TotalPricePopover: React.FC<TotalPricePopoverProps> = ({
  pricing,
  localCurrency,
  mayHaveFees,
}) => {
  const { t } = useTranslation();
  const { priceQuoteUnit } = usePriceQuoteUnit();
  const taxesAndFeesMessage = useTaxesAndFeesMessage(
    pricing.totalPaidAtHotel,
    mayHaveFees,
    localCurrency,
  );

  const showDynamicPricingMessage = useFeatureFlag('ShowDynamicPricingMessage');

  return (
    <Popover
      content={
        <div
          sx={{
            padding: 'xs',
          }}
        >
          {showDynamicPricingMessage && (
            <Paragraph variant="extrasmall">
              {t('sellingTotalPricePopover.dynamicPricing')}
            </Paragraph>
          )}
          {pricing.totalPaidAtHotel > 0 && (
            <div
              sx={{
                paddingY: 'xs',
              }}
            >
              {pricing.displayedPricingItems.map((pricingItem) => {
                return (
                  <BasketSummaryDetail
                    key={`BasketSummaryDetail-${pricingItem.label}`}
                    {...pricingItem}
                    loading={false}
                    priceQuoteUnit={priceQuoteUnit}
                    allowZeroPrice
                  />
                );
              })}
            </div>
          )}

          <Paragraph
            variant="extrasmall"
            sx={{
              color: 'textDimmedheavy',
            }}
          >
            {taxesAndFeesMessage}
          </Paragraph>
        </div>
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4xs',
      }}
    >
      <Icon
        size="20"
        name="Markers/Tooltip"
      />
    </Popover>
  );
};
